import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo"
import Contact from "../../components/contact.component"
import projectType from "../../utils/project-types"

const PortfolioTemplate = ({ data: { wordpressWpPortfolio, pinkDiamond } }) => {
  return (
    <Layout>
      <SEO
        title={wordpressWpPortfolio.title}
        description={wordpressWpPortfolio.excerpt}
      />
      <div
        className={
          "bg-blue-50 bg-no-repeat bg-hero-pofo bg-hero-pofo-p lg:bg-hero-pofo-p-lg xl:bg-hero-pofo-p-xl bg-hero-pofo-s md:bg-hero-pofo-s-lg xl:bg-hero-pofo-s-xl pt-32 sm:pt-36 lg:pt-40 pb-24 lg:pb-32 px-8 sm:px-28 lg:px-28 xl:px-60 relative"
        }
      >
        <div className={"mx-auto lg:max-w-3xl"}>
          <div className={"font-serif text-black text-2xl pb-2 xl:pb-3"}>
            Case Study
          </div>
          <div
            className={"font-serif text-pink text-4xl sm:text-5xl lg:text-7xl"}
          >
            {wordpressWpPortfolio.title}
          </div>
          <div
            className={"pt-4 lg:pt-5 lg:pr-5 pofo-desc"}
            dangerouslySetInnerHTML={{
              __html: wordpressWpPortfolio.crb_project_description,
            }}
          ></div>
          <div className="flex flex-col sm:flex-row sm:justify-between pt-4">
            <div className={"pb-2 sm:pb-0"}>
              <h3 className={"font-serif text-left text-xl sm:text-base pt-1"}>
                Client
              </h3>
              <div className={"font-sans text-left"}>
                {wordpressWpPortfolio.crb_client_name}
              </div>
            </div>
            <div className={"pb-2 sm:pb-0"}>
              <h3 className={"font-serif text-left text-xl sm:text-base pt-1"}>
                Project Date
              </h3>
              <div className={"font-sans text-left"}>
                {wordpressWpPortfolio.crb_project_date}
              </div>
            </div>
            <div>
              <h3 className={"font-serif text-left text-xl sm:text-base pt-1"}>
                Project Type
              </h3>
              <div className={"font-sans text-left"}>
                {projectType(wordpressWpPortfolio.crb_project_type)}
              </div>
            </div>
            <div></div>
            {wordpressWpPortfolio.crb_project_url && false && (
              <div className="flex items-center">
                <a href={wordpressWpPortfolio.crb_project_url} target="_blank">
                  View Project
                </a>
              </div>
            )}
          </div>
        </div>
        <img
          src={pinkDiamond.publicURL}
          className={"absolute -left-7 -bottom-10 sm:-bottom-14 w-20 sm:w-28"}
        />
      </div>
      <div className={"container mx-auto max-w-5xl pt-16 px-6"}>
        <h2 className={"text-center pt-2 pb-8 sm:pb-14"}>Project Visuals</h2>
        {wordpressWpPortfolio.crb_project_images.map(
          ({ crb_project_image }) => (
            <Img
              fluid={crb_project_image.localFile.childImageSharp.fluid}
              alt={crb_project_image.title}
              className={"mb-10 sm:mb-20"}
            />
          )
        )}
      </div>
      <Contact></Contact>
    </Layout>
  )
}

PortfolioTemplate.defaultProps = {
  title: null,
  crb_client_name: null,
  crb_project_date: null,
  crb_project_url: null,
  crb_project_type: "",
  crb_project_images: [],
  portfolio_categories: [],
  yoastTitle: null,
  yoastMeta: null,
}

PortfolioTemplate.propTypes = {
  title: PropTypes.string,
  crb_client_name: PropTypes.string,
  crb_project_date: PropTypes.string,
  crb_project_url: PropTypes.string,
  crb_project_type: PropTypes.string,
  crb_project_images: PropTypes.arrayOf(PropTypes.object),
  portfolio_categories: PropTypes.arrayOf(PropTypes.number),
  yoastTitle: PropTypes.string,
  yoastMeta: PropTypes.arrayOf(PropTypes.object),
}

export default PortfolioTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressWpPortfolio(wordpress_id: { eq: $id }, status: { eq: "publish" }) {
      id
      portfolio_categories
      title
      yoast_title
      yoast_meta {
        name
        property
      }
      crb_client_name
      crb_project_description
      crb_project_date(formatString: "YYYY")
      crb_project_url
      crb_project_type
      crb_project_images {
        crb_project_image {
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    pinkDiamond: file(relativePath: { eq: "bg/pink-diamond.svg" }) {
      publicURL
    }
  }
`
